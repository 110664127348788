<template>
	<div class="login">
		<v-img src="@/assets/thumbup.jpg" class="login-img"/>
		<v-card
				class="login-card"
		>
			<v-card-title primary-title>
				<h4 class="details-header">Welcome back!</h4>
			</v-card-title>
			<v-form>
				<v-text-field prepend-icon="fal fa-user" name="Username" label="Username" v-model="email" :error="hasError"
						@input="hasError = false"/>
				<v-text-field prepend-icon="fal fa-lock" name="Password" label="Password" type="password" v-model="password"
						:error="hasError" @keydown.native="passwordInput"/>
				<v-card-actions>
					<v-btn
							class="login-button"
							x-large
							:color="'#00aeef' || '#fb7c34'"
							block
							@click="login"
					>Login
					</v-btn>
				</v-card-actions>
				<div class="login-error" v-if="hasError">{{ errorMessage }}</div>
			</v-form>
			<v-dialog
					max-width="600"
			>
				<template #activator="{ on }">
					<a class="forgot-password" v-on="on" @click="showForgotPasswordModal">
						Forgot Password?
					</a>
				</template>
				<template #default="dialog">
					<v-card>
						<v-toolbar
								color="primary"
								dark
						>Which email to reset password?
						</v-toolbar>
						<v-card-text>
							<v-text-field
									prepend-icon="fal fa-user"
									name="Email"
									label="Email"
									v-model="forgotPasswordEmail"
									@input="forgotPasswordMessage = ''; forgotPasswordError = false;"
							/>
						</v-card-text>
						<div class="message"
							:class="{
								'error': forgotPasswordError
							}"
						>
							{{ forgotPasswordMessage }}
						</div>
						<v-card-actions class="justify-end">
							<v-btn
									text
									@click="dialog.value = false"
							>Close
							</v-btn>
							<v-btn
									color="success"
									@click="resetPassword(dialog)"
							>Submit
							</v-btn>
						</v-card-actions>
					</v-card>
				</template>
			</v-dialog>
		</v-card>

	</div>
</template>

<script>
import { auth } from '@/helpers/firebase';
import { store } from '@/helpers/store'

export default {
	name: 'Login',
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Login',
		meta: [
			{
				name: 'description',
				content: 'Login to your BioLab Fluid GF™ account.'
			},
		],
		// all titles will be injected into this template
		titleTemplate: '%s | Biolab fluid GF Q4206'
	},
	docs(v) {
		return {
			props: v.props,
			events: {},
			slots: {},
			components: v.components,
		};
	},
	props: {},
	data() {
		return {
			user: null,
			email: null,
			password: null,
			hasError: false,
			errorMessage: '',
			forgotPasswordEmail: '',
			forgotPasswordError: false,
			forgotPasswordMessage: '',
		};
	},
	computed: {
		validEmail() {
			return this.checkIfValidEmail(this.email);
		},
		validForgotPasswordEmail() {
			return this.checkIfValidEmail(this.forgotPasswordEmail);
		}
	},
	methods: {
		checkIfValidEmail(email) {
			return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
		},
		resetPassword(dialog) {
			if (!this.forgotPasswordEmail || !this.validForgotPasswordEmail) {
				this.forgotPasswordError = true;
				this.forgotPasswordMessage = 'Invalid Email, try again.';
				return;
			}

			auth.sendPasswordResetEmail(this.forgotPasswordEmail).then(() => {
				this.forgotPasswordMessage = 'Success, Email sent.'
				if (dialog) dialog.value = false
			}).catch(err => {
				this.forgotPasswordError = true;
				this.forgotPasswordMessage = 'Problem sending email, please check your email address and try again.'
				console.log(err);
			})
		},
		showForgotPasswordModal() {
			this.forgotPasswordEmail = '';
			this.forgotPasswordError = false;
			this.forgotPasswordMessage = '';
		},
		passwordInput(e) {
			this.hasError = false;
			if (e.key === 'Enter') {
				this.login();
			}
		},
		login() {
			if (!this.validEmail || !this.password) {
				this.hasError = true;
				this.errorMessage = 'Email and Password required';
				return;
			} else {
				this.hasError = false;
				this.errorMessage = '';
			}
			auth.signInWithEmailAndPassword(this.email, this.password)
					.then(() => {
						// Signed in
						// ...
						store.set('systemUser', true);
						this.$router.push('/app/home');
					})
					.catch((error) => {
						this.hasError = true;
						this.errorMessage = error.message;
						console.log(error);
					});
		},
	}
};
</script>

<style lang="scss" scoped>
.login {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;

	&-error {
		color: red;
		display: flex;
		justify-content: center;
	}

	&-button {
		color: white;
	}

	&-img {
		position: absolute;
		height: 100%;
		width: 100%;
	}

	&-card {
		flex: 1;
		max-width: 500px;
		width: 100%;
		padding: 2rem;
	}

	.forgot-password {
		font-size: 12px;
	}

	.message {
		.error {
			color: red;
		}
	}
}
</style>
